import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useRef,
  } from 'react';
  
  interface FieldMapping {
    id: number;
    zuoraFieldType: 'standard' | 'custom';
    zuoraField: string;
    zuoraCustomValue?: string;
    shopifyFieldType: 'standard' | 'meta';
    shopifyField: string;
    shopifyCustomValue?: string;
  }
  
  interface OutboundCFMappingContextType {
    mappingData: any;
    loading: boolean;
    error: string | null;
    selectedCategory: string;
    setSelectedCategory: (category: string) => void;
    mappings: FieldMapping[];
    addMapping: (isCustom?: boolean) => void;
    updateMapping: (id: number, field: string, value: string) => void;
    removeMapping: (id: number) => void;
    handleSave: () => Promise<void>;
    refreshData: () => Promise<any>;
    hasChanges: boolean;
    setError: (error: string | null) => void;
  }
  
  const categoryToOwnerTypeMap = {
    Account: 'CUSTOMER',
    Subscription: 'ORDER',
  };
  
  const OutboundCFMappingContext =
    createContext<OutboundCFMappingContextType>({
      mappingData: null,
      loading: false,
      error: null,
      selectedCategory: 'Account',
      setSelectedCategory: () => {},
      mappings: [],
      addMapping: () => {},
      updateMapping: () => {},
      removeMapping: () => {},
      handleSave: async () => {},
      refreshData: async () => null,
      hasChanges: false,
      setError: () => {},
    });
  
  export const useOutboundCFMapping = () =>
    useContext(OutboundCFMappingContext);
  
  interface ProviderProps {
    children: React.ReactNode;
  }
  
  export const OutboundCFMappingProvider: React.FC<ProviderProps> = ({
    children,
  }) => {
    const [mappingData, setMappingData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedCategory, setSelectedCategory] = useState('Account');
    const [mappings, setMappings] = useState<FieldMapping[]>([]);
    const [hasChanges, setHasChanges] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
  
    const dataFetchAttempted = useRef(false);
    const isMounted = useRef(true);
  
    useEffect(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);
  
    const fetchMappingData = async (category = selectedCategory) => {
      setLoading(true);
  
      try {
        const ownerType = categoryToOwnerTypeMap[category] || 'CUSTOMER';
        const response = await fetch(
          `/platform/gateway-proxy-v2/shopify-connector/provision/fields?ownerType=${ownerType}`,
        );
  
        if (!response.ok) {
          throw new Error('Failed to fetch mapping data');
        }
  
        const data = await response.json();
        setMappingData(data);
        return data;
      } catch (err) {
        setError('Failed to load field mapping data. Please try again later.');
        console.error('Error fetching mapping data:', err);
        throw err;
      } finally {
        setLoading(false);
      }
    };
  
    const addMapping = (isCustom = true) => {
      const newMapping: FieldMapping = {
        id: Date.now(),
        zuoraFieldType: isCustom ? 'custom' : 'standard',
        zuoraField: '',
        zuoraCustomValue: '',
        shopifyFieldType: isCustom ? 'meta' : 'standard',
        shopifyField: '',
        shopifyCustomValue: '',
      };
  
      setMappings([...mappings, newMapping]);
      if (!initialLoad) {
        setHasChanges(true);
      }
    };
  
    const updateMapping = (id: number, field: string, value: string) => {
      setMappings((prevMappings) =>
        prevMappings.map((mapping) =>
          mapping.id === id ? { ...mapping, [field]: value } : mapping,
        ),
      );
  
      if (!initialLoad) {
        setHasChanges(true);
      }
    };
  
    const removeMapping = (id: number) => {
      setMappings((prevMappings) =>
        prevMappings.filter((mapping) => mapping.id !== id),
      );
      if (!initialLoad) {
        setHasChanges(true);
      }
    };
  
    const handleSave = async (): Promise<void> => {
      setLoading(true);
  
      try {
        const missingMappings = mappings.some((mapping) => {
          const zuoraValid =
            mapping.zuoraFieldType === 'custom'
              ? Boolean(mapping.zuoraField)
              : Boolean(mapping.zuoraCustomValue);
          const shopifyValid =
            mapping.shopifyFieldType === 'meta'
              ? Boolean(mapping.shopifyField)
              : Boolean(mapping.shopifyCustomValue);
          return !(zuoraValid && shopifyValid);
        });
  
        if (missingMappings) {
          setError('Please complete all field mappings before saving.');
          return;
        }
  
        const ownerType = categoryToOwnerTypeMap[selectedCategory];
  
        const formattedMappings = mappings.reduce((acc, mapping) => {
          const zuoraKey =
            mapping.zuoraFieldType === 'custom'
              ? mapping.zuoraField
              : mapping.zuoraCustomValue;
  
          const shopifyKey =
            mapping.shopifyFieldType === 'meta'
              ? mapping.shopifyField
              : `${ownerType}.${mapping.shopifyCustomValue}`;
  
          if (zuoraKey && shopifyKey) {
            acc[shopifyKey] = zuoraKey;
          }
  
          return acc;
        }, {});
  
        const response = await fetch(
          `/platform/gateway-proxy-v2/shopify-connector/provision/meta-fields/mapping?ownerType=${ownerType}`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formattedMappings),
          },
        );
  
        if (!response.ok) {
          throw new Error('Failed to save mappings');
        }
  
        setHasChanges(false);
      } catch (err) {
        setError('Failed to save mappings. Please try again later.');
        console.error('Error saving mappings:', err);
      } finally {
        setLoading(false);
      }
    };
  
    const fetchExistingMappings = async (category: string) => {
      const ownerType = categoryToOwnerTypeMap[category]; // "CUSTOMER" or "ORDER"
      const response = await fetch(
        `/platform/gateway-proxy-v2/shopify-connector/provision/meta-fields/mapping`,
      );
  
      if (!response.ok) {
        throw new Error('Failed to fetch existing mappings');
      }
  
      const data = await response.json();
      const parsed: FieldMapping[] = [];
  
      const ownerData = data[ownerType];
      if (!ownerData) {
        return parsed;
      }
  
      if (ownerType === 'CUSTOMER') {
        if (ownerData.CUSTOMER_META_FIELD) {
          Object.entries(ownerData.CUSTOMER_META_FIELD).forEach(
            ([shopifyField, zuoraField]) => {
              parsed.push({
                id: Date.now() + Math.random(),
                zuoraFieldType: 'custom',
                zuoraField: zuoraField as string,
                zuoraCustomValue: '',
                shopifyFieldType: 'meta',
                shopifyField: shopifyField as string,
                shopifyCustomValue: '',
              });
            },
          );
        }
  
        if (ownerData.CUSTOMER_STANDARD_FIELD) {
          Object.entries(ownerData.CUSTOMER_STANDARD_FIELD).forEach(
            ([shopifyKey, zuoraKey]) => {
              parsed.push({
                id: Date.now() + Math.random(),
                zuoraFieldType: 'standard',
                zuoraField: '',
                zuoraCustomValue: zuoraKey as string,
                shopifyFieldType: 'standard',
                shopifyField: '',
                shopifyCustomValue: shopifyKey as string,
              });
            },
          );
        }
      }
  
      if (ownerType === 'ORDER') {
        if (ownerData.ORDER_STANDARD_FIELD) {
          Object.entries(ownerData.ORDER_STANDARD_FIELD).forEach(
            ([shopifyKey, zuoraKey]) => {
              parsed.push({
                id: Date.now() + Math.random(),
                zuoraFieldType: 'standard',
                zuoraField: '',
                zuoraCustomValue: zuoraKey as string,
                shopifyFieldType: 'standard',
                shopifyField: '',
                shopifyCustomValue: shopifyKey as string,
              });
            },
          );
        }
      }
  
      return parsed;
    };
  
    const refreshData = async () => {
      if (loading || dataFetchAttempted.current) {
        return;
      }
  
      dataFetchAttempted.current = true;
      setLoading(true);
      setError(null);
  
      try {
        const [fieldsData, initialMappings] = await Promise.all([
          fetchMappingData(selectedCategory),
          fetchExistingMappings(selectedCategory),
        ]);
  
        if (!isMounted.current) {
          return;
        }
  
        setMappingData(fieldsData);
  
        if (initialMappings.length > 0) {
          setMappings(initialMappings);
        } else {
          addMapping();
        }
  
        setHasChanges(false);
        setInitialLoad(false);
      } catch (err) {
        console.error('Error loading mapping data:', err);
        if (isMounted.current) {
          setError('Failed to load field mapping data. Please try again.');
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    };
  
    useEffect(() => {
      if (!mappingData && !loading && !dataFetchAttempted.current) {
        refreshData();
      }
    }, [mappingData, loading]);
  
    useEffect(() => {
      if (!initialLoad && mappings.length === 0) {
        addMapping();
      }
    }, [mappings.length]);
    const value: OutboundCFMappingContextType = {
      mappingData,
      loading,
      error,
      selectedCategory,
      setSelectedCategory,
      mappings,
      addMapping,
      updateMapping,
      removeMapping,
      handleSave,
      refreshData,
      hasChanges,
      setError,
    };
  
    return (
      <OutboundCFMappingContext.Provider value={value}>
        {children}
      </OutboundCFMappingContext.Provider>
    );
  };
  
  export default OutboundCFMappingContext;
  
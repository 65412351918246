import React, { createContext, useState, useCallback, useContext } from "react";

export interface ProcessItem {
  id: string;
  stepName: string;
  status: 'NOT_STARTED' | 'SUCCESS' | 'FAILED' | 'IN_PROGRESS';
  errorMessage: string | null;
  createdAt: string;
  updatedAt: string;
  [key: string]: any;
}

export interface Process {
  id: string;
  tenantId: string;
  sourceEntityId: string;
  sourceEntityType: string;
  status: 'NOT_STARTED' | 'SUCCESS' | 'IN_PROGRESS' | 'FAILED';
  processType: string;
  errorMessage: string | null;
  createdAt: string;
  updatedAt: string;
  processItems: ProcessItem[];
  [key: string]: any;
}

interface ProcessContextType {
  processes: Process[];
  loading: boolean;
  error: string | null;
  fetchProcesses: (tenantId: string) => Promise<void>;
  resetState: () => void;
}

export const ProcessContext = createContext<ProcessContextType>({
  processes: [],
  loading: false,
  error: null,
  fetchProcesses: async () => { },
  resetState: () => { },
});

export const useProcessContext = () => useContext(ProcessContext);

export const ProcessProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [processes, setProcesses] = useState<Process[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchProcesses = useCallback(async (tenantId: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `/platform/gateway-proxy-v2/shopify-connector/api/shopify-process/tenant/${tenantId}`
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        if (errorData?.messages?.length > 0) {
          throw new Error(errorData.messages[0]);
        } else {
          throw new Error(`Error fetching processes: ${response.status}`);
        }
      }

      const data = await response.json();
      const topTenProcesses = data.slice(0, 10);
      setProcesses(topTenProcesses);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching processes:", error);
      setError(error instanceof Error ? error.message : "Unknown error");
      setLoading(false);
    }
  }, []);

  const resetState = useCallback(() => {
    setProcesses([]);
    setLoading(false);
    setError(null);
  }, []);

  const contextValue = {
    processes,
    loading,
    error,
    fetchProcesses,
    resetState,
  };

  return (
    <ProcessContext.Provider value={contextValue}>
      {children}
    </ProcessContext.Provider>
  );
};
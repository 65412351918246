import React, { useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {
  Button,
  Card,
  Typography,
  TextField,
  Grid,
} from '@platform-ui/design-system';
import DeleteIcon from '@mui/icons-material/Delete';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useOutboundCFMapping } from '../context/OutboundCFMappingContext';


interface OutboundCFMappingCardProps {
  id: string;
}

const OutboundMappingCard: React.FC<OutboundCFMappingCardProps> = ({ id }) => {
  const {
    mappingData,
    loading,
    error,
    selectedCategory,
    setSelectedCategory,
    mappings,
    addMapping,
    updateMapping,
    removeMapping,
    handleSave,
    refreshData,
    hasChanges,
    setError,
  } = useOutboundCFMapping();

  useEffect(() => {
    if (!mappingData) {
      refreshData().catch((error) => {
        console.error('Error loading data:', error);
      });
    }
  }, [mappingData, refreshData]);

  const CustomCard = ({ children, variant = 'outlined', sx = {} }) => (
    <Box
      sx={{
        border: variant === 'outlined' ? '1px solid #e0e0e0' : 'none',
        borderRadius: 1,
        backgroundColor: 'white',
        ...sx,
      }}
    >
      {children}
    </Box>
  );

  const FilterSelect = ({
    options,
    value,
    onChange,
    label,
    placeholder = 'Search or Select Fields',
  }) => {
    const [searchText, setSearchText] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = React.useRef(null);
    const selectedOption = options.find((opt) => opt.value === value);
    const displayValue = selectedOption ? selectedOption.label : value || '';
    const filteredOptions =
      searchText.trim() === ''
        ? options
        : options.filter(
            (option) =>
              option.label.toLowerCase().includes(searchText.toLowerCase()) ||
              (typeof option.value === 'string' &&
                option.value.toLowerCase().includes(searchText.toLowerCase())),
          );
    useEffect(() => {
      if (!isOpen) {
        return;
      }

      const handleClickOutside = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () =>
        document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen]);

    return (
      <Box ref={containerRef} sx={{ position: 'relative' }}>
        <Typography variant="caption" component="div" sx={{ mb: 1 }}>
          {label}
        </Typography>

        <TextField
          fullWidth
          value={isOpen ? searchText : displayValue}
          placeholder={placeholder}
          dsOnFocus={() => setIsOpen(true)}
          dsOnChange={(e) => setSearchText(e.target.value)}
          error={false}
        />

        {isOpen && (
          <Box
            sx={{
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              maxHeight: '200px',
              overflowY: 'auto',
              mt: 1,
              backgroundColor: 'white',
              zIndex: 1500,
            }}
          >
            {filteredOptions.length === 0 ? (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2">No options found</Typography>
              </Box>
            ) : (
              filteredOptions.map((option) => (
                <Box
                  key={option.value}
                  onClick={() => {
                    onChange(option.value);
                    setIsOpen(false);
                  }}
                  sx={{
                    p: 2,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                    ...(option.value === value
                      ? { backgroundColor: 'rgba(25, 118, 210, 0.08)' }
                      : {}),
                  }}
                >
                  <Typography variant="body2">{option.label}</Typography>
                </Box>
              ))
            )}
          </Box>
        )}
      </Box>
    );
  };

  const getOptions = (type) => {
    if (!mappingData) {
      return [];
    }

    switch (type) {
      case 'zuoraCustom':
        return (mappingData.ZuoraCustomFields || []).map((field) => ({
          label: field.label,
          value: field.key,
          type: 'custom',
        }));

      case 'zuoraStandard': {
        const standardFields = (mappingData.ZuoraStandardFields || []).map(
          (field) => ({
            label: field.label || field.name,
            value: field.key || field.name,
            type: 'standard',
          }),
        );
        if (standardFields.length === 0) {
          const categoryFields = {
            Account: [
              {
                label: 'Account Number',
                value: 'AccountNumber',
                type: 'standard',
              },
            ],
            Subscription: [
              {
                label: 'Subscription Number',
                value: 'SubscriptionNumber',
                type: 'standard',
              },
              { label: 'Order Number', value: 'OrderNumber', type: 'standard' },
            ],
          };
          return categoryFields[selectedCategory] || [];
        }

        return standardFields;
      }

      case 'shopifyAll': {
        const standard = (mappingData.ShopifyStandardFields || []).map(
          (field) => ({
            label: field.name,
            value: field.name,
            type: 'standard',
          }),
        );

        const meta = (mappingData.ShopifyMetaFields || []).map((field) => ({
          label: field.key,
          value: field.key,
          type: 'meta',
        }));

        return [...standard, ...meta];
      }

      default:
        return [];
    }
  };

  const renderCategories = () => (
    <CustomCard sx={{ height: '100%' }}>
      <List sx={{ width: '100%', p: 0 }}>
        {['Account', 'Subscription'].map((category) => {
          const isSelected = selectedCategory === category;
          return (
            <ListItem
              key={category}
              disablePadding
              selected={isSelected}
              sx={{
                bgcolor: isSelected
                  ? 'rgba(25, 118, 210, 0.08)'
                  : 'transparent',
                '&:hover': {
                  bgcolor: isSelected
                    ? 'rgba(25, 118, 210, 0.12)'
                    : 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <ListItemButton
                onClick={() => setSelectedCategory(category)}
                sx={{ py: 1.5 }}
              >
                <Typography variant="body2">
                  {category.replace(/([A-Z])/g, ' $1').trim()}
                </Typography>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </CustomCard>
  );

  useEffect(() => {
    if (mappingData) {
      refreshData().catch((error) => {
        console.error('Error loading data after category change:', error);
      });
    }
  }, [selectedCategory]);

  const renderError = () =>
    error && (
      <Box
        sx={{
          mb: 2,
          p: 2,
          bgcolor: '#FFEBEE',
          borderRadius: 1,
          border: '1px solid #ffcdd2',
        }}
      >
        <Typography color="error">{error}</Typography>
        <Button
          dsOnClick={() => setError(null)}
          variant="text"
          size="small"
          sx={{ mt: 1 }}
        >
          Dismiss
        </Button>
      </Box>
    );

  const renderMappingFields = () => {
    if (!mappingData) {
      return null;
    }

    return (
      <Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, gap: 1 }}
        >
          <Button
            dsOnClick={() => addMapping(true)}
            variant="outlined"
            size="small"
          >
            + Custom Field
          </Button>
          <Button
            dsOnClick={() => addMapping(false)}
            variant="outlined"
            size="small"
          >
            + Standard Field
          </Button>
        </Box>

        <CustomCard sx={{ mb: 3 }}>
          <Box
            sx={{ p: 2, borderBottom: '1px solid #e0e0e0', bgcolor: '#f5f5f5' }}
          >
            <Typography variant="subtitle2">Field Mappings</Typography>
          </Box>

          {mappings.map((mapping, index) => (
            <Box
              key={mapping.id}
              sx={{
                p: 2,
                borderBottom:
                  index < mappings.length - 1 ? '1px solid #e0e0e0' : 'none',
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  {mapping.zuoraFieldType === 'custom' ? (
                    <FilterSelect
                      options={getOptions('zuoraCustom')}
                      value={mapping.zuoraField || ''}
                      onChange={(value) =>
                        updateMapping(mapping.id, 'zuoraField', value)
                      }
                      label="Zuora Custom Field"
                    />
                  ) : (
                    <FilterSelect
                      options={getOptions('zuoraStandard')}
                      value={mapping.zuoraCustomValue || ''}
                      onChange={(value) =>
                        updateMapping(mapping.id, 'zuoraCustomValue', value)
                      }
                      label="Zuora Standard Field"
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <SwapHorizIcon color="action" />
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <FilterSelect
                      options={getOptions('shopifyAll')}
                      value={
                        mapping.shopifyFieldType === 'meta'
                          ? mapping.shopifyField || ''
                          : mapping.shopifyCustomValue || ''
                      }
                      onChange={(value) => {
                        const option = getOptions('shopifyAll').find(
                          (opt) => opt.value === value,
                        );
                        const type = option?.type || 'meta';
                        updateMapping(
                          mapping.id,
                          type === 'meta'
                            ? 'shopifyField'
                            : 'shopifyCustomValue',
                          value,
                        );
                        updateMapping(mapping.id, 'shopifyFieldType', type);
                      }}
                      label="Shopify Field"
                    />
                  </Box>
                  <IconButton
                    color="error"
                    onClick={() => removeMapping(mapping.id)}
                    disabled={mappings.length === 1}
                    size="small"
                    sx={{ mt: 3 }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ))}
        </CustomCard>
      </Box>
    );
  };

  const cardBody = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '550px' }}>
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden', p: 2, gap: 2 }}>
        <Box sx={{ width: '25%' }}>{renderCategories()}</Box>
        <Box sx={{ width: '75%', overflowY: 'auto' }}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {renderError()}
              {renderMappingFields()}
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <Button
          dsOnClick={handleSave}
          variant="contained"
          disabled={loading || !hasChanges}
        >
          Save
        </Button>
      </Box>
    </Box>
  );

  return (
    <Card
      id={id}
      e2e="outbound-custom-fields-mapping-card"
      body={cardBody}
      fullWidth
    />
  );
};

export default OutboundMappingCard;

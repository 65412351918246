import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
  ReactNode,
} from "react";
import Connect from "../../../../Connect/Connect";

interface MappingField {
  [fieldName: string]: boolean;
}

interface MappingCategory {
  [category: string]: MappingField;
}

interface SelectedFields {
  Product: { [field: string]: boolean };
  ProductRatePlan: { [field: string]: boolean };
  ProductRatePlanCharge: { [field: string]: boolean };
}

interface InboundCFMappingContextType {
  mappingData: MappingCategory | null;
  loading: boolean;
  error: string | null;
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
  selectedFields: SelectedFields;
  hasChanges: boolean;
  handleFieldToggle: (field: string) => void;
  handleSave: () => Promise<void>;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  refreshData: () => Promise<void>;
}

export const InboundCFMappingContext = createContext<
  InboundCFMappingContextType | undefined
>(undefined);

interface ProviderProps {
  children: ReactNode;
}

export const InboundCFMappingProvider: React.FC<ProviderProps> = ({
  children,
}) => {
  const [mappingData, setMappingData] = useState<MappingCategory | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [initialFields, setInitialFields] = useState<SelectedFields>({
    Product: {},
    ProductRatePlan: {},
    ProductRatePlanCharge: {},
  });
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>("Product");
  const [selectedFields, setSelectedFields] = useState<SelectedFields>({
    Product: {},
    ProductRatePlan: {},
    ProductRatePlanCharge: {},
  });
  const connect = window.connect as Connect;
  const tenantId = connect.tenant.tenant_id;

  const dataFetchAttempted = useRef(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchMappingData = useCallback(async () => {
    if (loading || (dataFetchAttempted.current && mappingData !== null)) {
      return;
    }

    dataFetchAttempted.current = true;
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        "/platform/gateway-proxy-v2/shopify-connector/provision/custom-fields/mapping",
        {
          method: "GET",
          headers: {
            "Accept": "application/json"
          }
        }
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        if (errorData?.messages?.length > 0) {
          throw new Error(errorData.messages[0]);
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }

      if (!isMounted.current) {
        return;
      }

      const responseData = await response.json();
      const initialSelection: SelectedFields = {
        Product: {},
        ProductRatePlan: {},
        ProductRatePlanCharge: {},
      };

      Object.keys(responseData).forEach((category) => {
        if (
          ["Product", "ProductRatePlan", "ProductRatePlanCharge"].includes(
            category
          )
        ) {
          const fields = responseData[category];
          initialSelection[category as keyof SelectedFields] = Object.keys(
            fields
          ).reduce((acc, field) => {
            acc[field] = fields[field];
            return acc;
          }, {} as { [field: string]: boolean });
        }
      });

      const initialFieldsCopy = {
        Product: { ...initialSelection.Product },
        ProductRatePlan: { ...initialSelection.ProductRatePlan },
        ProductRatePlanCharge: { ...initialSelection.ProductRatePlanCharge },
      };

      setMappingData(responseData);
      setSelectedFields(initialSelection);
      setInitialFields(initialFieldsCopy);
      setHasChanges(false);
    } catch (err) {
      console.error("Error fetching mapping data:", err);
      if (isMounted.current) {
        setError(
          err instanceof Error ? err.message : "Failed to load custom fields mapping data. Please try again."
        );
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  }, [tenantId]);

  const checkForChanges = useCallback(
    (newFields: SelectedFields): boolean => {
      const categories = [
        "Product",
        "ProductRatePlan",
        "ProductRatePlanCharge",
      ] as const;

      for (const category of categories) {
        const currentFields = newFields[category];
        const initialCategoryFields = initialFields[category];

        for (const field in currentFields) {
          if (currentFields[field] !== initialCategoryFields[field]) {
            return true;
          }
        }
      }

      return false;
    },
    [initialFields]
  );

  const handleFieldToggle = useCallback(
    (field: string) => {
      setSelectedFields((prev) => {
        const updatedCategory = {
          ...prev[selectedCategory as keyof typeof prev],
          [field]: !prev[selectedCategory as keyof typeof prev][field],
        };
        const newFields = {
          ...prev,
          [selectedCategory]: updatedCategory,
        };
        const anyChanges = checkForChanges(newFields);
        setHasChanges(anyChanges);

        return newFields;
      });
    },
    [selectedCategory, checkForChanges]
  );

  const handleSave = useCallback(async () => {
    if (loading || !hasChanges) {
      return;
    }

    const postData = {
      Product: selectedFields.Product,
      ProductRatePlan: selectedFields.ProductRatePlan,
      ProductRatePlanCharge: selectedFields.ProductRatePlanCharge,
    };

    try {
      setLoading(true);
      const response = await fetch(
        "/platform/gateway-proxy-v2/shopify-connector/provision/custom-fields/mapping",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          },
          body: JSON.stringify(postData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        if (errorData?.messages?.length > 0) {
          throw new Error(errorData.messages[0]);
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }

      if (!isMounted.current) {
        return;
      }

      setInitialFields({
        Product: { ...selectedFields.Product },
        ProductRatePlan: { ...selectedFields.ProductRatePlan },
        ProductRatePlanCharge: { ...selectedFields.ProductRatePlanCharge },
      });

      setHasChanges(false);
    } catch (err) {
      console.error("Error saving mapping data:", err);
      if (isMounted.current) {
        setError(err instanceof Error ? err.message : "Failed to save custom fields mapping. Please try again.");
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  }, [loading, hasChanges, selectedFields, tenantId]);

  useEffect(() => {
    if (!mappingData && !loading && !dataFetchAttempted.current) {
      fetchMappingData();
    }
  }, [mappingData, loading]);

  const contextValue = React.useMemo(
    () => ({
      mappingData,
      loading,
      error,
      selectedCategory,
      setSelectedCategory,
      selectedFields,
      hasChanges,
      handleFieldToggle,
      handleSave,
      setError,
      refreshData: fetchMappingData,
    }),
    [
      mappingData,
      loading,
      error,
      selectedCategory,
      selectedFields,
      hasChanges,
      handleFieldToggle,
      handleSave,
      fetchMappingData,
    ]
  );

  return (
    <InboundCFMappingContext.Provider value={contextValue}>
      {children}
    </InboundCFMappingContext.Provider>
  );
};

export default InboundCFMappingProvider;

export const useInboundCFMapping = (): InboundCFMappingContextType => {
  const context = useContext(InboundCFMappingContext);
  if (context === undefined) {
    throw new Error(
      "useInboundCFMapping must be used within a InboundCFMappingProvider"
    );
  }
  return context;
};
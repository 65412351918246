import React, { useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  CircularProgress,
} from "@mui/material";
import { Button, Card, Checkbox, Typography } from "@platform-ui/design-system";
import { useInboundCFMapping } from "../context/InboundCFMappingContext";

interface InboundCFMappingCardProps {
  id: string;
}

const InboundMappingCard: React.FC<InboundCFMappingCardProps> = ({ id }) => {
  const {
    mappingData,
    loading,
    error,
    selectedCategory,
    setSelectedCategory,
    selectedFields,
    handleFieldToggle,
    handleSave,
    refreshData,
    hasChanges,
    setError,
  } = useInboundCFMapping();

  useEffect(() => {
    if (!mappingData) {
      refreshData().catch((error) => {
        console.error("Error loading data:", error);
      });
    }
  }, [mappingData]);

  const formatCategoryName = (category: string): string => {
    return category.replace(/([A-Z])/g, " $1").trim();
  };

  const hiddenFields = [
    "shopify_sync_status__c",
    "sync_to_shopify__c",
    "shopify_sync_date__c",
  ];

  const CustomCard = ({ children, variant = "outlined", sx = {} }) => (
    <Box sx={{
      border: variant === "outlined" ? "1px solid #e0e0e0" : "none",
      borderRadius: 1,
      backgroundColor: "white",
      ...sx
    }}>
      {children}
    </Box>
  );

  const renderFields = () => {
    if (!mappingData || !mappingData[selectedCategory]) {
      return <Typography>No fields available</Typography>;
    }

    const fields = Object.keys(mappingData[selectedCategory]).filter(
      (field) => !hiddenFields.includes(field)
    );

    return (
      <Box sx={{ width: "100%" }}>
        {fields.length === 0 ? (
          <Typography>No mappable fields available</Typography>
        ) : (
          fields.map((field) => (
            <Box key={field} sx={{ py: 1 }}>
              <Checkbox
                checked={
                  !!selectedFields[
                  selectedCategory as keyof typeof selectedFields
                  ][field]
                }
                dsOnChange={() => handleFieldToggle(field)}
                e2e={`checkbox-${field}`}
                label={field}
                margin="none"
              />
            </Box>
          ))
        )}
      </Box>
    );
  };

  const renderCategories = () => {
    const categories = ["Product", "ProductRatePlan", "ProductRatePlanCharge"];

    return (
      <List sx={{ width: "100%", p: 0 }}>
        {categories.map((category) => {
          const isSelected = selectedCategory === category;
          return (
            <ListItem
              key={category}
              disablePadding
              selected={isSelected}
              sx={{
                bgcolor: isSelected
                  ? "rgba(25, 118, 210, 0.08)"
                  : "transparent",
                "&:hover": {
                  bgcolor: isSelected
                    ? "rgba(25, 118, 210, 0.12)"
                    : "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <ListItemButton
                onClick={() => setSelectedCategory(category)}
                sx={{ py: 1.5 }}
              >
                <Typography variant="body2">
                  {formatCategoryName(category)}
                </Typography>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const renderError = () => {
    if (!error) return null;

    return (
      <Box
        sx={{
          mb: 2,
          p: 2,
          bgcolor: "#FFEBEE",
          borderRadius: 1,
          border: "1px solid #ffcdd2",
        }}
      >
        <Typography color="error">{error}</Typography>
        <Box sx={{ mt: 1 }}>
          <Button dsOnClick={() => setError(null)} variant="text" size="small">
            Dismiss
          </Button>
        </Box>
      </Box>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <>
        {renderError()}
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: "medium" }}>
          {formatCategoryName(selectedCategory)} Fields
        </Typography>
        {renderFields()}
      </>
    );
  };

  const cardBody = (
    <Box sx={{ display: "flex", flexDirection: "column", height: "400px" }}>
      <Box sx={{ display: "flex", flex: 1, overflow: "hidden", p: 2, gap: 2 }}>
        <CustomCard
          sx={{
            width: "30%",
            borderRight: "1px solid #e0e0e0",
            overflowY: "auto",
          }}
        >
          {renderCategories()}
        </CustomCard>
        <CustomCard
          sx={{
            width: "70%",
            p: 2,
            overflowY: "auto",
          }}
        >
          {renderContent()}
        </CustomCard>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Button
          dsOnClick={handleSave}
          variant="contained"
          disabled={loading || !hasChanges}
        >
          Save
        </Button>
      </Box>
    </Box>
  );

  return (
    <Card
      id={id}
      e2e="inbound-custom-fields-mapping-card"
      body={cardBody}
      fullWidth
    />
  );
};

export default InboundMappingCard;
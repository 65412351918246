import React, { useEffect, useState, useMemo } from "react";
import { Box } from "@mui/material";
import {
  Table,
  Typography,
  Chip,
  ChipProps,
  Button,
  Icon,
} from "@platform-ui/design-system";
import styled from "@emotion/styled";
import {
  DataType,
  ColumnType,
} from "@platform-ui/design-system/dist/types/Table";
import {
  Process,
  ProcessProvider,
  useProcessContext,
} from "../context/ProcessContext";
import Connect from "../../../../Connect/Connect";

const TabContainer = styled.div`
  padding: 20px;
`;

const ExpandButton = styled(Button)`
  min-width: auto;
  padding: 4px 8px;
  margin-right: 8px;
`;

const NestedRow = styled.div<{ level: number }>`
  padding-left: ${({ level }) => level * 10}px;
  display: flex;
  align-items: center;
`;

interface TaskHistoryProps {
  tenantId?: string;
  tabName?: string;
}

const formatDisplayText = (text: string): string => {
  if (!text) {
    return "";
  }
  return text.split(" ").map(word => {
    if (word.includes("_")) {
      return word
        .split("_")
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
        .join(" ");
    } else if (word === word.toUpperCase() && word.length > 1) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    } else {
      return word
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase())
        .trim();
    }
  }).join(" ");
};

const PROCESS_STATUS_MAP: Record<
  string,
  { label: string; state: ChipProps["state"] }
> = {
  SUCCESS: { label: "Success", state: "success" },
  IN_PROGRESS: { label: "In Progress", state: "info" },
  FAILED: { label: "Failed", state: "error" },
  NOT_STARTED: { label: "Not Started", state: "default" },
};

const PROCESS_ITEM_STATUS_MAP = PROCESS_STATUS_MAP;

const TaskHistoryContent: React.FC<TaskHistoryProps> = () => {
  const { processes, loading, error, fetchProcesses } = useProcessContext();
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const connect = window.connect as Connect;
  const tenantId = connect.tenant.tenant_id;

  const toggleRowExpand = (rowId: string) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(rowId)) {
        newSet.delete(rowId);
      } else {
        newSet.add(rowId);
      }
      return newSet;
    });
  };

  const handleRefresh = () => {
    if (tenantId) {
      fetchProcesses(tenantId);
    }
  };

  useEffect(() => {
    if (tenantId) {
      fetchProcesses(tenantId);
    }
  }, [tenantId]);

  const groupedProcesses = useMemo(() => {
    if (!processes || processes.length === 0) {
      return [];
    }

    const entityTypeGroups: Record<string, Process[]> = {};

    processes.forEach((process) => {
      const entityType = process.sourceEntityType;
      if (!entityTypeGroups[entityType]) {
        entityTypeGroups[entityType] = [];
      }
      entityTypeGroups[entityType].push(process);
    });

    return Object.entries(entityTypeGroups).map(([entityType, items]) => {
      const success = items.filter(
        (item) => item.status === "SUCCESS"
      ).length;
      const inProgress = items.filter(
        (item) => item.status === "IN_PROGRESS"
      ).length;
      const failed = items.filter(
        (item) => item.status === "FAILED"
      ).length;
      const notStarted = items.filter(
        (item) => item.status === "NOT_STARTED"
      ).length;

      const latestUpdate = new Date(
        Math.max(...items.map((item) => new Date(item.updatedAt).getTime()))
      ).toLocaleString();

      return {
        id: `group-${entityType}`,
        nodeType: "entityType",
        entityType,
        displayName: formatDisplayText(entityType),
        processes: items,
        success,
        inProgress,
        failed,
        notStarted,
        latestUpdate,
      };
    });
  }, [processes]);

  const allFlattenedRows = useMemo(() => {
    const result: DataType[] = [];

    groupedProcesses.forEach((group) => {
      group.processes.forEach((process) => {
        const processRow = {
          ...process,
          id: process.id,
          nodeType: "process",
          level: 0,
          displayName: formatDisplayText(`${process.sourceEntityType} ${process.processType}`),
          hasChildren: process.processItems && process.processItems.length > 0,
        };

        result.push(processRow);

        if (process.processItems?.length > 0 && expandedRows.has(process.id)) {
          process.processItems.forEach((item) => {
            result.push({
              ...item,
              parentId: process.id,
              nodeType: "processItem",
              level: 1,
              displayName: item.stepName || "Unknown Step",
              hasChildren: false,
            });
          });
        }
      });
    });

    return result;
  }, [groupedProcesses, expandedRows]);

  const columns: ColumnType[] = [
    {
      field: "expand",
      label: "",
      sortable: false,
      dsRenderCell: ({ row }: { row: DataType; value?: any }) => {
        const rowId = row.id as string;
        const hasChildren = row.hasChildren as boolean;
        const isExpanded = expandedRows.has(rowId);

        if (!hasChildren) {
          return <span style={{ width: "28px" }} />;
        }

        return (
          <ExpandButton variant="text" dsOnClick={() => toggleRowExpand(rowId)}>
            <Icon body={isExpanded ? "arrow_drop_down" : "arrow_right"} />
          </ExpandButton>
        );
      },
    },
    {
      field: "displayName",
      label: "Name",
      sortable: false,
      width: 400,
      dsRenderCell: ({ row }: { row: DataType; value?: any }) => {
        const level = (row.level as number) || 0;
        return (
          <NestedRow level={level}>
            <Typography variant="body1">
              {row.displayName as string}
            </Typography>
          </NestedRow>
        );
      },
    },
    {
      field: "status",
      label: "Status",
      sortable: false,
      width: 200,
      dsRenderCell: ({ row }: { row: DataType; value?: any }) => {
        const nodeType = row.nodeType as string;
        if (nodeType === "entityType") {
          const success = row.success as number;
          const inProgress = row.inProgress as number;
          const failed = row.failed as number;
          const notStarted = row.notStarted as number;

          return (
            <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
              {success > 0 && (
                <Chip state="success" label={`${success} Success`} />
              )}
              {inProgress > 0 && (
                <Chip state="info" label={`${inProgress} In Progress`} />
              )}
              {failed > 0 && <Chip state="error" label={`${failed} Failed`} />}
              {notStarted > 0 && (
                <Chip state="default" label={`${notStarted} Not Started`} />
              )}
            </Box>
          );
        } else if (nodeType === "process" || nodeType === "processItem") {
          const status = row.status as string;
          const statusMap =
            nodeType === "process"
              ? PROCESS_STATUS_MAP
              : PROCESS_ITEM_STATUS_MAP;

          const { label, state } = statusMap[status] || {
            label: status,
            state: "default" as ChipProps["state"],
          };

          return <Chip state={state} label={label} />;
        }

        return null;
      },
    },
    {
      field: "updatedTime",
      label: "Last Updated",
      sortable: false,
      width: 200,
      dsRenderCell: ({ row }: { row: DataType; value?: any }) => {
        const nodeType = row.nodeType as string;

        if (nodeType === "entityType") {
          return <span>{row.latestUpdate as string}</span>;
        } else {
          const dateStr = row.updatedAt as string;
          return (
            <span>{dateStr ? new Date(dateStr).toLocaleString() : "-"}</span>
          );
        }
      },
    },
    {
      field: "errorMessage",
      label: "Error",
      sortable: false,
      dsRenderCell: ({ row }: { row: DataType; value?: any }) => {
        const nodeType = row.nodeType as string;
        return nodeType === "entityType" ? null : (
          <span>{row.errorMessage || "-"}</span>
        );
      },
    },
  ];

  return (
    <Box component={TabContainer as any}>
      <Box mb={3} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="title">Task History</Typography>
        <Button
          variant="outlined"
          dsOnClick={handleRefresh}
          disabled={loading}
        >
          {loading ? "Refreshing..." : "Refresh"}
        </Button>
      </Box>

      {loading && (
        <Box minHeight="400px" display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body1">Loading process history...</Typography>
        </Box>
      )}

      {error && !loading && (
        <Box minHeight="400px" display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body1" color="error">Error loading process history: {error}</Typography>
        </Box>
      )}

      {!loading && !error && (!processes || processes.length === 0) && (
        <Box minHeight="400px" display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body1">No process history found</Typography>
        </Box>
      )}

      {!loading && !error && processes && processes.length > 0 && (
        <Table
          columns={columns}
          rows={allFlattenedRows}
          uniqueKey="id"
          e2e="processHistoryTable"
          rowsPerPage={20}
        />
      )}
    </Box>
  );
};

const TaskHistory: React.FC<TaskHistoryProps> = (props) => {
  return (
    <ProcessProvider>
      <TaskHistoryContent {...props} />
    </ProcessProvider>
  );
};

export default TaskHistory;